import React from "react";

const LandingPage = () => {
  return (
    <>
      <nav class="bg-blue-500 p-4">
        <div class="container mx-auto flex justify-between items-center">
          <a href="#" class="text-white text-xl font-semibold">
            Vamshi
          </a>
          <ul class="flex space-x-4">
            <li>
              <a href="#portfolio" class="text-white">
                Portfolio
              </a>
            </li>
            <li>
              <a href="#skills" class="text-white">
                Skills
              </a>
            </li>
            <li>
              <a href="#contact" class="text-white">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <header class="bg-blue-600 text-white text-center py-20">
        <div class="container mx-auto">
          <h1 class="text-4xl font-semibold mb-2 animate__animated animate__fadeInDown">
            Welcome to my Web Design Portfolio
          </h1>
          <p class="text-lg animate__animated animate__fadeInUp">
            I'm Vamshi, a passionate and creative web designer.
          </p>
        </div>
      </header>

      <section id="portfolio" class="py-16">
        <div class="container mx-auto">
          <h2 class="text-2xl font-semibold mb-8">Portfolio Highlights</h2>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"></div>
        </div>
      </section>

      <section id="skills" class="bg-gray-200 py-16">
        <div class="container mx-auto">
          <h2 class="text-2xl font-semibold mb-8">Skills and Tools</h2>
          <ul class="list-disc ml-6">
            <li>
              Web Design: UI/UX design, responsive design, wireframing,
              prototyping.
            </li>
            <li>Coding Languages: HTML5, CSS3, JavaScript (basic), SCSS.</li>
            <li>
              Design Tools: Adobe Creative Suite (Photoshop, Illustrator, XD),
              Figma.
            </li>
            <li>CMS Platforms: WordPress, Wix.</li>
            <li>Version Control: Git, GitHub.</li>
          </ul>
        </div>
      </section>

      <section id="contact" class="py-16">
        <div class="container mx-auto">
          <h2 class="text-2xl font-semibold mb-8">Contact Information</h2>
          <p>
            If you're interested in collaborating or have inquiries, reach out
            to me:
          </p>
          <ul class="mt-4">
            <li>
              Email: vamshimylabathula@gmail.com
              <a href="mailto:your.email@example.com">your.email@example.com</a>
            </li>
            <li>Phone: 7729902535</li>
            <li>
              Website:www.vamshicreates.com
              <a href="http://www.yourwebsite.com">www.yourwebsite.com</a>
            </li>
            <li>
              LinkedIn:{" "}
              <a href="https://www.linkedin.com/in/yourname">
                linkedin.com/in/yourname
              </a>
            </li>
          </ul>
        </div>
      </section>

      <footer class="bg-blue-500 text-white text-center py-4">
        <p>&copy; 2024 Vamshi. All rights reserved.</p>
      </footer>
    </>
  );
};

export default LandingPage;
